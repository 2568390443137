import { global } from ":global";
import { _Firebase } from "./_model";

export async function addWorldCloudValue(
  props: _Firebase.AddWordCloudWordProps
) {
  const event_id = String(props.eventId);
  const ans = String(props.ans);
  global.fb.rtdb.set({
    path: `/wordcloud/${event_id}/answers/${ans}`,
    value: props.val,
  });
}
